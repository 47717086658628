<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Station Occurrence Details
        </h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
          <asom-form-field label="Occurrence Number">
            <p class="font-semibold">
              {{ get(itemData, "occurrenceNo", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Date Created">
            <p class="font-semibold">
              {{ formatDate(get(itemData, "dateCreated", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field label="Is Item Opened">
            <p class="font-semibold">
              {{ get(itemData, "isItemOpened") ? "Open" : "Closed" }}
            </p>
          </asom-form-field>
          <asom-form-field label="Is Action Required">
            <p class="font-semibold">
              {{
                get(itemData, "isActionRequired")
                  ? "Action Required"
                  : "No Action Required"
              }}
            </p>
          </asom-form-field>
          <asom-form-field
            v-if="itemData.isExpiryDateRequired"
            label="Expiry Date"
          >
            <p class="font-semibold">
              {{ formatDate(get(itemData, "expiryDate", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field label="Description" class="col-span-2">
            <p class="font-semibold">
              {{ get(itemData, "description", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Remarks" class="col-span-2">
            <p class="font-semibold">
              {{ get(itemData, "remarks", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="itemData.files.length > 0"
            label="Supporting Document"
          >
            <asom-upload-file-list :files="itemData.files" disabled-remove />
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
        <asom-button
          v-if="canEditStationOccurrence"
          size="sm"
          outline
          text="Edit"
          @click="navigateToUpdate"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getFaultOccurrenceDetails } from "../../../services/faultManagement.service";

export default {
  name: "ViewOrder",
  data() {
    return {
      selectedItem: null,
      itemData: {
        occurrenceNo: get(this.$route.params, "occurrenceNo", null),
        description: get(this.$route.params, "description", null),
        remarks: get(this.$route.params, "remarks", null),
        dateCreated: get(this.$route.params, "dateCreated", null),
        expiryDate: get(this.$route.params, "expiryDate", null),
        isActionRequired: get(this.$route.params, "isActionRequired", null),
        isItemOpened: get(this.$route.params, "isItemOpened", null),
        isExpiryDateRequired: get(
          this.$route.params,
          "isExpiryDateRequired",
          false
        ),
        files: [],
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      canEditStationOccurrence: "auth/canEditStationOccurrence",
    }),
  },
  methods: {
    get,
    navigateToUpdate() {
      this.$router.push({
        name: "Edit Station Occurrence",
        params: this.selectedItem,
      });
    },
    formatDate(date) {
      if (date == null) return "-";
      else return displayUtcDate(date);
    },
    async getDetails() {
      const result = await getFaultOccurrenceDetails({
        faultOccurrenceId: get(this.$route.params, "occurenceId"),
        stationId: this.stationId,
      });
      if (result.success) {
        this.selectedItem = get(result.payload, "model");
        this.setFormData(get(result.payload, "model"));
      }
    },
    setFormData(data) {
      this.itemData.occurrenceNo = get(data, "occurrenceNo", "-");
      this.itemData.description = get(data, "description", "-");
      this.itemData.remarks = get(data, "remarks", "-");
      this.itemData.dateCreated = get(data, "dateCreated", null);
      this.itemData.expiryDate = get(data, "expiryDate", null);
      this.itemData.isExpiryDateRequired = get(
        data,
        "isExpiryDateRequired",
        false
      );
      this.itemData.isActionRequired = get(data, "isActionRequired", "-");
      this.itemData.isItemOpened = get(data, "isItemOpened", "-");
      let attachments = get(data, "attachments", null);
      if (attachments.length > 0) this.itemData.files = attachments;
    },
  },
};
</script>
